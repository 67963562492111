<template>
<div>
    <v-row align="center" class="mx-1 mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class=" btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>{{ $t('idhafh') }} {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="name" label="الإسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
    </v-row>
    <v-card outlined>
        <Loading v-if="$global.state.loading"/>
        <v-data-table v-if="!$global.state.loading" :key="$global.state.loading" class="mytable" :items-per-page="$global.state.filter.pageSize" :items.sync="items" :loading="$global.state.loading" :headers="headers" hide-default-footer :loading-text="$t('loading')" :no-data-text="$t('no-data')" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id)" v-on="on" icon>
                            <v-icon color="error">
                                delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('hthf') }}</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="info">
                                edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{ $t('edit') }}
                    </span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>

<script>
export default {
    components: {
        Dialog: () => import('./dialog'),
    },
    data() {
        return {
            items: [],
            name: '',
            headers: [{
                    text: this.$t('ism-almhzwr'),
                    value: 'name'
                },
                {
                    text: this.$t('mlahzh'),
                    value: 'note'
                },
                {
                    text: '',
                    value: 'actions'
                }
            ]
        }
    },

    mounted() {
        this.getItems()

        this.$eventBus.$on(`refresh`, () => {
            this.getItems()
        })

    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`)
    },

    methods: {
        async getItems() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(`BlackList?&PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.name}&IsDeleted=false&Sort=desc`);
                this.items = res.data.result;
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.$global.state.loading = false;
            }
        },

        async deleteItem(id) {
            await this.$service.deleteItem(id, `BlackList`, `delete`, this.$t('hl-ant-mtakd-mn-htha-alijraa'))
            await this.$eventBus.$once(`delete`, () => {
                setTimeout(() => {this.getItems() }, 10);
                
            })
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },
    },
}
</script>
